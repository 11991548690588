<script lang="ts" setup>
import { ref, computed, defineEmits, inject, onMounted } from 'vue'
import { Link, router, usePage } from '@inertiajs/vue3'
import { FeatureSettings, UserResource } from '@/Types/generated'
import NotificationBell from '@/Components/Notifications/NotificationBell.vue'
import UploadMediaModal from '@/Components/Media/UploadMediaModal.vue'
import CreateMediaExportModal from '@/Components/Media/CreateMediaExportModal.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface LinkItem {
  name: string;
  href: string;
  method?: string;
  active?: boolean;
}

const page = usePage()

const props = withDefaults(
  defineProps<{
    dark?: boolean,
    withGalleryButtons?: boolean
  }>(),
  {
    dark: false,
    withGalleryButtons: false
  }
)

const isOpen = ref(false)
const uploadFilesModalOpen = ref(false)
const createMediaExportModalOpen = ref(false)

const features = computed<FeatureSettings>(() => page.props.features)
const user = computed<UserResource|null>(() => page.props.user)

const emit = defineEmits(['media-uploaded'])

const isFeatureEnabled = (feature: keyof FeatureSettings): boolean => {
  return features.value[feature]
}

const links = computed<LinkItem[]>((): LinkItem[] => {
  const links: LinkItem[] = [{ name: 'Home', href: route('home'), active: route().current() === 'home' }]

  if (isFeatureEnabled('excursions_enabled')) {
    links.push({ name: 'Excursions', href: route('excursions.index'), active: route().current() === 'excursions.index' })
  }

  if (isFeatureEnabled('activities_enabled')) {
    links.push({
      name: 'Activities',
      href: route('activities.index'),
      active: route().current() === 'activities.index' || route().current() === 'activities.show' || route().current() === 'activities.create' || route().current() === 'activities.edit'
    })
  }

  if (isFeatureEnabled('board_games_enabled')) {
    links.push({ name: 'Board Games', href: route('board-games.index'), active: route().current() === 'board-games.index' })
  }

  if (isFeatureEnabled('gallery_enabled')) {
    links.push({
      name: 'Gallery',
      href: route('media.index'),
      active: route().current() === 'media.index' || route().current() === 'media.show'
    })
  }

  if (isOpen.value) {
    if (user.value) {
      links.push({ name: 'Account', href: route('account.edit') })
    }

    links.push({
      name: user.value ? 'Logout' : 'Login',
      href: user.value ? route('logout') : route('login'),
      method: user.value ? 'POST' : 'GET'
    })
  }

  return links
})

const closeUploadMediaModal = () => {
  uploadFilesModalOpen.value = false

  if (route().current('media.index') && (route().params.upload === 'true' || route().params.upload === '1')) {
    const url = new URL(window.location.href)
    url.searchParams.delete('upload')
    window.history.replaceState({}, '', url)
  }
}

router.on('navigate', () => {
  isOpen.value = false
})

onMounted(() => {
  if (route().current('media.index') && (route().params.upload === 'true' || route().params.upload === '1')) {
    uploadFilesModalOpen.value = true
  }
})
</script>

<template>
  <nav
    :class="[
      'flex',
      'items-center',
      'justify-between',
      'p-3',
      {'bg-purple-300': !props.dark},
      {'bg-gray-800': props.dark},
      'sticky',
      'top-0',
      'z-50',
      'h-16'
    ]"
  >
    <div class="md:hidden">
      <button
        id="hamburger"
        @click.prevent="isOpen = !isOpen"
      >
        <img
          class="toggle block"
          :src="!isOpen ? 'https://img.icons8.com/fluent-systems-regular/2x/menu-squared-2.png' : 'https://img.icons8.com/fluent-systems-regular/2x/close-window.png'"
          :alt="!isOpen ? 'Open menu' : 'Close menu'"
          width="40"
          height="40"
        >
      </button>
    </div>

    <div class="hidden md:block w-1/4" />

    <div class="hidden md:flex justify-center items-center w-1/2">
      <Link
        v-for="(link, idx) in links"
        :key="'link-' + idx"
        prefetch
        :href="link.href"
        :method="link.method ? link.method : 'GET'"
        :class="[
          {'text-gray-900': link.active && !props.dark},
          {'text-gray-700': !link.active && !props.dark},
          {'text-gray-100': link.active && props.dark},
          {'text-gray-300': !link.active && props.dark},
          {'hover:text-gray-500': !link.active && !props.dark},
          {'hover:text-gray-300': !link.active && props.dark},
          {'font-bold': link.active},
          'px-3',
          'py-2',
          'text-base',
          'whitespace-nowrap'
        ]"
      >
        {{ link.name }}
      </Link>
    </div>

    <div class="flex space-x-2 items-center justify-end w-1/4">
      <NotificationBell
        class="mr-4"
        :dark="props.dark"
      />

      <button
        v-if="user && props.withGalleryButtons"
        class="px-4 py-2 bg-gray-700 hover:bg-gray-500 text-white rounded mr-2 text-base"
        @click="uploadFilesModalOpen = true"
      >
        Upload
      </button>

      <button
        v-if="features.download_all_media_enabled && props.withGalleryButtons"
        class="px-4 py-2 bg-purple-900 hover:bg-purple-500 text-white rounded text-base"
        @click="createMediaExportModalOpen = true"
      >
        Export
      </button>

      <Link
        v-if="user && !props.withGalleryButtons"
        prefetch="click"
        :href="route('account.edit')"
        class="px-4 py-2 bg-gray-700 hover:bg-gray-500 text-white rounded mr-2 hidden md:block text-base"
      >
        Account
      </Link>
      <Link
        v-if="!props.withGalleryButtons"
        prefetch="click"
        :href="user ? route('logout') : route('login')"
        :method="user ? 'POST' : 'GET'"
        class="px-4 py-2 bg-purple-900 hover:bg-purple-500 text-white rounded hidden md:block text-base"
      >
        {{ user ? 'Logout' : 'Login' }}
      </Link>
    </div>

    <div
      :class="[
        {'hidden': !isOpen},
        'absolute',
        'top-full',
        'left-0',
        'right-0',
        {'bg-purple-300': !props.dark},
        {'bg-gray-800': props.dark},
        'md:hidden',
      ]"
    >
      <Link
        v-for="(link, idx) in links"
        :key="'mobile-link-' + idx"
        prefetch="click"
        :href="link.href"
        :method="link.method ? link.method : 'GET'"
        :class="[
          'block',
          {'text-gray-700': !props.dark},
          {'text-gray-300': props.dark},
          {'hover:text-gray-500': !props.dark},
          {'hover:text-gray-300': props.dark},
          'px-3',
          'py-3',
          'border-b',
          {'border-blue-900': !props.dark},
          {'border-gray-700': props.dark},
          {'font-bold': link.active}
        ]"
      >
        {{ link.name }}
      </Link>
    </div>
  </nav>

  <UploadMediaModal
    v-if="user"
    :show="uploadFilesModalOpen"
    @close="closeUploadMediaModal"
    @media-uploaded="emit('media-uploaded')"
  />

  <CreateMediaExportModal
    v-if="features.download_all_media_enabled"
    :show="createMediaExportModalOpen"
    @close="createMediaExportModalOpen = false"
  />
</template>

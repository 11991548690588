<script lang="ts" setup>
import { computed, defineProps } from 'vue'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { format, isBefore } from 'date-fns'
import { EventAttendanceEnum, EventResource } from '@/Types/generated'
import CalendarLinksDropdown from '@/Components/Activities/CalendarLinksDropdown.vue'

const emit = defineEmits(['open-modal'])

const props = defineProps<{
  event: EventResource
  index: number
}>()

const { event, index } = props

const isRsvpClosed = computed(() => {
  return event.rsvp_by ? isBefore(new Date(event.rsvp_by), new Date()) : false
})

const isPastEvent = computed(() => {
  return event.starts_at ? isBefore(new Date(event.starts_at), new Date()) : false
})

const formatDate = (dateString: string) => {
  return format(new Date(dateString), 'MMMM dd, yyyy h:mm a')
}

const openModal = () => {
  emit('open-modal', event)
}
</script>

<template>
  <div class="grid grid-cols-1 gap-10 sm:gap-0 sm:grid-cols-2">
    <div
      :class="{'order-1 sm:order-2': index % 2 === 0, 'order-1 sm:order-1': index % 2 !== 0}"
      class="flex justify-center items-center"
    >
      <div class="w-full aspect-[4/3] relative bg-purple-100 p-4">
        <div
          class="absolute inset-0 bg-center bg-cover m-4"
          :style="{ backgroundImage: `url(${event.image})` }"
        />
      </div>
    </div>

    <div
      :id="event.slug"
      :class="{'order-2 sm:order-1': index % 2 === 0, 'order-2 sm:order-2': index % 2 !== 0}"
      class="mt-4 flex items-center justify-center flex-col py-8"
    >
      <div class="flex items-center justify-center flex-col text-center pb-4">
        <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
          {{ event.name }}
        </h2>

        <div class="flex items-center flex-row justify-center mt-2 mb-1">
          <div class="text-gray-400 px-2">
            ——
          </div>
          <div class="flex items-center">
            <LavenderIcon />
          </div>
          <div class="text-gray-400 px-2">
            ——
          </div>
        </div>

        <div
          v-if="event.starts_at"
          class="text-gray-500 pb-3 mt-1"
        >
          {{ formatDate(event.starts_at) }}
        </div>
      </div>
      <div class="flex items-center justify-center flex-col text-center">
        <p
          class="text-gray-500 px-10 leading-8"
          v-html="event.details"
        />
      </div>

      <div class="mt-4 text-center mb-6">
        <template v-if="!isPastEvent && event.attendance === 'not_invited'">
          <p class="text-red-300">
            Unfortunately, this event is very limited for spaces, and is for close family only.
          </p>
        </template>
        <template v-else-if="!isPastEvent && event.attendance === EventAttendanceEnum.NotResponded && !isRsvpClosed">
          <a
            href="javascript:void(0)"
            class="inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
            @click="openModal"
          >RSVP</a>
        </template>
        <template v-else-if="!isPastEvent && event.attendance === EventAttendanceEnum.Attending">
          <p class="text-green-500">
            You are attending this event.
          </p>

          <p
            v-if="event.table"
            class="text-gray-500 px-10 leading-8"
          >
            You are seated at <strong>{{ event.table.name }}</strong>.
          </p>

          <div class="mt-4 space-y-2">
            <div>
              <CalendarLinksDropdown
                v-if="event.calendar_links"
                :calendar-links="event.calendar_links"
              />
            </div>

            <div>
              <a
                href="#"
                class="inline-flex items-center px-4 py-2 bg-gray-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
                @click.prevent="openModal"
              >
                Change Response
              </a>
            </div>
          </div>
        </template>
        <template v-else-if="!isPastEvent && event.attendance === EventAttendanceEnum.NotAttending">
          <p class="text-red-400">
            You are unable to attend this event.
          </p>
          <a
            href="#"
            class="mt-4 inline-flex items-center px-4 py-2 bg-gray-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
            @click.prevent="openModal"
          >
            Change Response
          </a>
        </template>
        <template v-else-if="!isPastEvent && isRsvpClosed">
          <p class="text-gray-500">
            RSVP period has ended.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted, onUnmounted, computed, inject } from 'vue'
import { Link, Head, router } from '@inertiajs/vue3'
import { format } from 'date-fns'
import { useToastMessage } from '@/Composables/UseToasts'
import { Commentable, MediaResource, UserResource } from '@/Types/generated'
import { makeConfirmationModal } from '@/Helpers/ConfirmationModalHelper'
import CommentsArea from '@/Components/Comments/CommentsArea.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import ConfirmationModal from '@/Components/UI/Modals/ConfirmationModal.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const props = defineProps<{
  media: MediaResource
  previous?: MediaResource
  next?: MediaResource
  user?: UserResource
  flash: { message: string } | null;
}>()

const isPublic = ref<boolean>(props.media.is_public)
const isBackground = ref<boolean>(props.media.is_background)
const commentsContainer = ref<HTMLDivElement | null>(null)

const isVideo = computed(() => {
  return props.media.mime_type.includes('video')
})

function handleKeydown (event: KeyboardEvent) {
  if (event.key === 'ArrowLeft') {
    if (props.previous) {
      event.preventDefault()
      router.visit(route('media.show', { id: props.previous.uuid }))
    }
  } else if (event.key === 'ArrowRight') {
    if (props.next) {
      event.preventDefault()
      router.visit(route('media.show', { id: props.next.uuid }))
    }
  }
}

onMounted(() => {
  if (commentsContainer.value) {
    commentsContainer.value.scrollTop = commentsContainer.value.scrollHeight
  }
  window.addEventListener('keydown', handleKeydown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeydown)
})

function deleteMedia () {
  makeConfirmationModal(isVideo.value ? 'video' : 'image')
    .then(() => {
      router.delete(route('media.destroy', props.media.uuid), {
        preserveScroll: true
      })
    })
    .catch(() => {
      console.log('User cancelled the action')
    })
}

function togglePublic () {
  router.post(route('media.update', props.media.uuid), { is_public: isPublic.value }, {
    preserveScroll: true
  })
}

function toggleBackground () {
  if (isVideo.value) {
    return
  }

  router.post(route('media.update', props.media.uuid), { is_background: isBackground.value }, {
    preserveScroll: true
  })
}

const formatDate = (dateString: string) => {
  return format(new Date(dateString), 'MMMM dd, yyyy h:mm a')
}

/* eslint-disable */
defineOptions({
  layout: (h, page) => h(AppLayout, { dark: true, 'with-gallery-buttons': true }, [page]),
})
/* eslint-enable */

useToastMessage(props)
</script>

<template>
  <Head title="Gallery | Hargrave Wedding" />
  <ConfirmationModal />

  <div class="flex flex-col h-screen text-white">
    <div class="flex flex-col flex-grow">
      <div class="relative flex-grow bg-black flex flex-col items-center justify-center overflow-hidden">
        <div class="flex-grow flex items-center justify-center max-h-[70vh]">
          <video
            v-if="isVideo"
            controls
            class="max-w-full max-h-full object-contain"
            :poster="props.media.thumbnail_url"
          >
            <source
              :src="props.media.url"
              :type="props.media.mime_type"
            >
          </video>
          <img
            v-else
            :src="props.media.url"
            alt=""
            class="max-w-full max-h-full object-contain"
          >
        </div>
        <div
          class="absolute bottom-0 left-0 m-4 p-2 bg-black bg-opacity-50 rounded text-white text-sm flex items-center"
        >
          <img
            v-if="props.media.user && props.media.user.guest"
            :src="props.media.user.guest.avatar"
            :alt="props.media.user.guest.name"
            class="w-6 h-6 rounded-full inline-block mr-2"
          >
          <a
            v-else-if="props.media.photographer"
            :href="props.media.photographer.url"
          >
            <img
              :src="props.media.photographer.avatar"
              :alt="props.media.photographer.name"
              class="w-6 h-6 rounded-full inline-block mr-2"
            >
          </a>

          <div>
            <div
              v-if="props.media.user"
            >
              Uploaded by {{ props.media.user.name }}
            </div>
            <a
              v-else-if="props.media.photographer"
              :href="props.media.photographer.url"
              class="text-purple-300 underline"
            >
              Captured by {{ props.media.photographer.name }}
            </a>
            <div v-if="props.media.taken_at">
              Taken on {{ formatDate(props.media.taken_at) }}
            </div>
            <div
              v-if="media.can?.update"
              class="flex flex-col mt-2"
            >
              <div class="flex items-center mb-2">
                <label
                  for="isPublicToggle"
                  class="mr-2"
                >Public:</label>
                <input
                  id="isPublicToggle"
                  v-model="isPublic"
                  type="checkbox"
                  @change="togglePublic"
                >
              </div>
              <div
                v-if="!isVideo"
                class="flex items-center"
              >
                <label
                  for="isBackgroundToggle"
                  class="mr-2"
                >Background:</label>
                <input
                  id="isBackgroundToggle"
                  v-model="isBackground"
                  type="checkbox"
                  @change="toggleBackground"
                >
              </div>
            </div>
          </div>
          <button
            v-if="props.media.can?.delete"
            class="ml-2 text-red-500 hover:text-red-700"
            @click="deleteMedia"
          >
            <i class="fas fa-trash-alt" />
          </button>
        </div>
        <Link
          v-if="props.previous"
          class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          :href="route('media.show', { id: props.previous.uuid })"
        >
          <i class="fas fa-arrow-left" />
        </Link>
        <Link
          v-if="props.next"
          class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          :href="route('media.show', { id: props.next.uuid })"
        >
          <i class="fas fa-arrow-right" />
        </Link>
      </div>
    </div>

    <div class="w-full bg-gray-200 text-black flex-shrink-0">
      <div
        ref="commentsContainer"
        class="bg-gray-100 p-4 sm:p-8 md:p-16 pt-0"
      >
        <div class="p-4">
          <h2 class="text-purple-500 text-lg mb-2">
            Comments
          </h2>

          <CommentsArea
            :commentable-type="Commentable.Media"
            :commentable-id="props.media.id"
            :comment-count="props.media.comments_count ?? 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .relative.flex-grow .flex-grow {
    max-height: 70vh;
  }
}
</style>

<script lang="ts" setup>
import { Head, usePage } from '@inertiajs/vue3'
import { useToastMessage } from '@/Composables/UseToasts'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import MediaCardPage from '@/Pages/Media/Partials/MediaCardPage.vue'
import { UserResource } from '@/Types/generated'
import { useScrollPosition } from '@/Composables/UseScrollPosition'
import BackToTopButton from '@/Components/UI/BackToTopButton.vue'
import AppLayout from '@/Layouts/AppLayout.vue'

interface Page {
  page: number
  count: number
}

const props = defineProps<{ mediaCount: number, perPage: number, flash: {message: string} }>()
const page = usePage()

const user = computed<UserResource|null|undefined>(() => page.props.user)

const resetKey = ref<number>(0)

const mediaPages = computed<Page[]>(() => {
  const pages: Page[] = []

  const totalPages = Math.ceil(props.mediaCount / props.perPage)
  const remainder = props.mediaCount % props.perPage ? props.mediaCount % props.perPage : props.perPage

  for (let i = 1; i <= totalPages; i++) {
    pages.push({
      page: i,
      count: i === totalPages ? remainder : props.perPage
    } as Page)
  }

  return pages
})

onUnmounted(() => {
  sessionStorage.setItem('scrollPosition', window.scrollY.toString())
})

onMounted(() => {
  const savedPosition = sessionStorage.getItem('scrollPosition')
  if (savedPosition) {
    window.scrollTo(0, parseInt(savedPosition))
    sessionStorage.removeItem('scrollPosition')
  }
})

/* eslint-disable */
defineOptions({
  layout: (h, page) => h(AppLayout, { dark: true, 'with-gallery-buttons': true }, [page]),
})
/* eslint-enable */

useToastMessage(props)
useScrollPosition('gallery')
</script>

<template>
  <Head title="Gallery | Hargrave Wedding" />

  <div class="flex flex-col h-screen text-white">
    <div class="flex-grow bg-black p-4">
      <div class="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-6 gap-4">
        <MediaCardPage
          v-for="(mediaPage, index) in mediaPages"
          :key="index+'-'+resetKey"
          :page="mediaPage"
        />
      </div>
    </div>

    <div
      v-if="!user"
      class="fixed bottom-0 left-0 right-0 bg-purple-600 py-2 px-4 shadow-lg"
    >
      <div class="container mx-auto flex justify-center items-center space-x-4">
        <p class="text-white font-semibold">
          Log in to see more or upload your own!
        </p>
        <a
          :href="route('login')"
          class="bg-white text-purple-600 hover:bg-purple-100 font-bold py-1 px-3 rounded transition duration-300 ease-in-out text-sm"
        >
          Log In
        </a>
      </div>
    </div>
  </div>

  <BackToTopButton
    :class="user ? '' : 'bottom-16'"
  />
</template>

<style scoped>
</style>

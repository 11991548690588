import { ref, onMounted, onUnmounted } from 'vue'

export function useScrollPosition (pageKey: string) {
  const scrollPosition = ref(0)

  const saveScrollPosition = () => {
    scrollPosition.value = window.pageYOffset

    if (scrollPosition.value > 0 && pageKey) {
      sessionStorage.setItem(`scrollPosition_${pageKey}`, scrollPosition.value.toString())
    }
  }

  const restoreScrollPosition = () => {
    const savedPosition = sessionStorage.getItem(`scrollPosition_${pageKey}`)
    if (savedPosition !== null) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedPosition))
      }, 100)
    }
  }

  onMounted(() => {
    window.addEventListener('scroll', saveScrollPosition)
    restoreScrollPosition()
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', saveScrollPosition)
  })

  return {
    scrollPosition,
    saveScrollPosition,
    restoreScrollPosition
  }
}
